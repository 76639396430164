import { getOwner } from "@ember/application";
import Component from "@ember/component";
import { alias } from "@ember/object/computed";
import { schedule, scheduleOnce } from "@ember/runloop";
import { isBlank } from "@ember/utils";
import $ from "jquery";
import ClickTrack from "discourse/lib/click-track";
import { highlightPost } from "discourse/lib/utilities";
import Scrolling from "discourse/mixins/scrolling";
import { bind, observes } from "discourse-common/utils/decorators";
export default Component.extend(Scrolling, dt7948.p({
  userFilters: alias("topic.userFilters"),
  classNameBindings: ["multiSelect", "topic.archetype", "topic.is_warning", "topic.category.read_restricted:read_restricted", "topic.deleted:deleted-topic"],
  menuVisible: true,
  SHORT_POST: 1200,
  postStream: alias("topic.postStream"),
  dockAt: 0,
  _enteredTopic() {
    // Ember is supposed to only call observers when values change but something
    // in our view set up is firing this observer with the same value. This check
    // prevents scrolled from being called twice
    if (this.enteredAt && this.lastEnteredAt !== this.enteredAt) {
      schedule("afterRender", this.scrolled);
      this.set("lastEnteredAt", this.enteredAt);
    }
  },
  _highlightPost(postNumber) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (isBlank(options.jump) || options.jump !== false) {
      scheduleOnce("afterRender", null, highlightPost, postNumber);
    }
  },
  init() {
    this._super(...arguments);
    this.appEvents.on("discourse:focus-changed", this, "gotFocus");
    this.appEvents.on("post:highlight", this, "_highlightPost");
  },
  didInsertElement() {
    this._super(...arguments);
    this.bindScrolling();
    window.addEventListener("resize", this.scrolled);
    $(this.element).on("click.discourse-redirect", ".cooked a, a.track-link", e => ClickTrack.trackClick(e, getOwner(this)));
  },
  willDestroy() {
    this._super(...arguments);

    // this happens after route exit, stuff could have trickled in
    this.appEvents.off("discourse:focus-changed", this, "gotFocus");
    this.appEvents.off("post:highlight", this, "_highlightPost");
  },
  willDestroyElement() {
    this._super(...arguments);
    this.unbindScrolling();
    window.removeEventListener("resize", this.scrolled);

    // Unbind link tracking
    $(this.element).off("click.discourse-redirect", ".cooked a, a.track-link");
  },
  gotFocus(hasFocus) {
    if (hasFocus) {
      this.scrolled();
    }
  },
  scrolled() {
    if (this.isDestroyed || this.isDestroying || this._state !== "inDOM") {
      return;
    }
    const offset = window.pageYOffset || document.documentElement.scrollTop;
    this.set("hasScrolled", offset > 0);

    // Trigger a scrolled event
    this.appEvents.trigger("topic:scrolled", offset);
  }
}, [["method", "_enteredTopic", [observes("enteredAt")]], ["method", "scrolled", [bind]]]));