import { modifier } from "ember-modifier";
export default modifier((element, _ref, _ref2) => {
  let [callback] = _ref;
  let {
    threshold = 1
  } = _ref2;
  const observer = new IntersectionObserver(entries => {
    entries.forEach(callback, {
      threshold
    });
  });
  observer.observe(element);
  return () => {
    observer.disconnect();
  };
});