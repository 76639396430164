import Component from "@glimmer/component";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class TopicViews extends Component {
  adjustAggregatedData(stats1) {
    const adjustedStats1 = [];
    stats1.forEach(stat1 => {
      const localDate1 = new Date(`${stat1.viewed_at}T00:00:00Z`);
      const localDateStr1 = localDate1.toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });
      const existingStat1 = adjustedStats1.find(s1 => s1.dateStr === localDateStr1);
      if (existingStat1) {
        existingStat1.views += stat1.views;
      } else {
        adjustedStats1.push({
          dateStr: localDateStr1,
          views: stat1.views,
          localDate: localDate1
        });
      }
    });
    return adjustedStats1.map(stat1 => ({
      viewed_at: stat1.localDate.toISOString().split("T")[0],
      views: stat1.views
    }));
  }
  formatDate(date1) {
    return date1.toLocaleDateString(undefined, {
      month: "2-digit",
      day: "2-digit"
    });
  }
  get updatedStats() {
    const adjustedStats1 = this.adjustAggregatedData(this.args.views.stats);
    let stats1 = adjustedStats1.map(stat1 => {
      const statDate1 = new Date(`${stat1.viewed_at}T00:00:00`).getTime();
      const localStatDate1 = new Date(statDate1);
      return {
        ...stat1,
        statDate: localStatDate1,
        label: this.formatDate(localStatDate1)
      };
    });
    return stats1;
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <div class="topic-views__wrapper">
        {{#each this.updatedStats as |stat|}}
          <div class="topic-views">
            <div class="topic-views__count">
              {{stat.views}}
            </div>
            <div class="topic-views__date">
              {{stat.label}}
            </div>
          </div>
        {{/each}}
      </div>
    
  */
  {
    "id": "bsuWRop4",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"topic-views__wrapper\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"updatedStats\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"topic-views\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"topic-views__count\"],[12],[1,\"\\n            \"],[1,[30,1,[\"views\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"topic-views__date\"],[12],[1,\"\\n            \"],[1,[30,1,[\"label\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"stat\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/topic-views.js",
    "isStrictMode": true
  }), this))();
}