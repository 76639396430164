import { hash } from "@ember/helper";
import PluginOutlet from "discourse/components/plugin-outlet";
import PrivateMessageMap from "discourse/components/topic-map/private-message-map";
import TopicMapSummary from "discourse/components/topic-map/topic-map-summary";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const TopicMap = setComponentTemplate(createTemplateFactory(
/*
  
  {{#unless @model.postStream.loadingFilter}}
    <section class="topic-map__contents">
      <TopicMapSummary
        @topic={{@model}}
        @topicDetails={{@topicDetails}}
        @postStream={{@postStream}}
      />
    </section>

    <section class="topic-map__additional-contents toggle-summary">
      <PluginOutlet
        @name="topic-map-expanded-after"
        @defaultGlimmer={{true}}
        @outletArgs={{hash topic=@model postStream=@postStream}}
      />
    </section>

    {{#if @showPMMap}}
      <section class="topic-map__private-message-map">
        <PrivateMessageMap
          @topicDetails={{@topicDetails}}
          @showInvite={{@showInvite}}
          @removeAllowedGroup={{@removeAllowedGroup}}
          @removeAllowedUser={{@removeAllowedUser}}
        />
      </section>
    {{/if}}
  {{/unless}}

*/
{
  "id": "OCmHKk8D",
  "block": "[[[1,\"\\n\"],[41,[51,[30,1,[\"postStream\",\"loadingFilter\"]]],[[[1,\"    \"],[10,\"section\"],[14,0,\"topic-map__contents\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@topic\",\"@topicDetails\",\"@postStream\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"section\"],[14,0,\"topic-map__additional-contents toggle-summary\"],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@name\",\"@defaultGlimmer\",\"@outletArgs\"],[\"topic-map-expanded-after\",true,[28,[32,2],null,[[\"topic\",\"postStream\"],[[30,1],[30,3]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,4],[[[1,\"      \"],[10,\"section\"],[14,0,\"topic-map__private-message-map\"],[12],[1,\"\\n        \"],[8,[32,3],null,[[\"@topicDetails\",\"@showInvite\",\"@removeAllowedGroup\",\"@removeAllowedUser\"],[[30,2],[30,5],[30,6],[30,7]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[\"@model\",\"@topicDetails\",\"@postStream\",\"@showPMMap\",\"@showInvite\",\"@removeAllowedGroup\",\"@removeAllowedUser\"],false,[\"unless\",\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/index.js",
  "scope": () => [TopicMapSummary, PluginOutlet, hash, PrivateMessageMap],
  "isStrictMode": true
}), templateOnly());
export default TopicMap;