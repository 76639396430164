import Component from "@glimmer/component";
import replaceEmoji from "discourse/helpers/replace-emoji";
import and from "truth-helpers/helpers/and";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const TRUNCATE_LENGTH_LIMIT = 85;
export default class TopicMapLink extends Component {
  get linkClasses() {
    return this.args.attachment ? "topic-link track-link attachment" : "topic-link track-link";
  }
  get truncatedContent() {
    const content1 = this.args.title || this.args.url;
    return content1.length > TRUNCATE_LENGTH_LIMIT ? `${content1.slice(0, TRUNCATE_LENGTH_LIMIT).trim()}...` : content1;
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <a
        class={{this.linkClasses}}
        href={{@url}}
        title={{@url}}
        data-user-id={{@userId}}
        data-ignore-post-id="true"
        target="_blank"
        rel="nofollow ugc noopener noreferrer"
      >
        {{#if @title}}
          {{replaceEmoji this.truncatedContent}}
        {{else}}
          {{this.truncatedContent}}
        {{/if}}
      </a>
      {{#if (and @title @rootDomain)}}
        <span class="domain">
          {{@rootDomain}}
        </span>
      {{/if}}
    
  */
  {
    "id": "z9dKte03",
    "block": "[[[1,\"\\n    \"],[10,3],[15,0,[30,0,[\"linkClasses\"]]],[15,6,[30,1]],[15,\"title\",[30,1]],[15,\"data-user-id\",[30,2]],[14,\"data-ignore-post-id\",\"true\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"nofollow ugc noopener noreferrer\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[1,[28,[32,0],[[30,0,[\"truncatedContent\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"truncatedContent\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[28,[32,1],[[30,3],[30,4]],null],[[[1,\"      \"],[10,1],[14,0,\"domain\"],[12],[1,\"\\n        \"],[1,[30,4]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@url\",\"@userId\",\"@title\",\"@rootDomain\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/topic-map-link.js",
    "scope": () => [replaceEmoji, and],
    "isStrictMode": true
  }), this))();
}