import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { htmlSafe } from "@ember/template";
import PluginOutlet from "discourse/components/plugin-outlet";
import dIcon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import escape from "discourse-common/lib/escape";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class AboutPage extends Component {
  get moderatorsCount() {
    return this.args.model.moderators.length;
  }
  get adminsCount() {
    return this.args.model.admins.length;
  }
  get stats() {
    return [{
      class: "members",
      icon: "users",
      text: I18n.t("about.member_count", {
        count: this.args.model.stats.users_count,
        formatted_number: I18n.toNumber(this.args.model.stats.users_count, {
          precision: 0
        })
      })
    }, {
      class: "admins",
      icon: "shield-alt",
      text: I18n.t("about.admin_count", {
        count: this.adminsCount,
        formatted_number: I18n.toNumber(this.adminsCount, {
          precision: 0
        })
      })
    }, {
      class: "moderators",
      icon: "shield-alt",
      text: I18n.t("about.moderator_count", {
        count: this.moderatorsCount,
        formatted_number: I18n.toNumber(this.moderatorsCount, {
          precision: 0
        })
      })
    }];
  }
  get contactInfo() {
    const url1 = escape(this.args.model.contact_url || "");
    const email1 = escape(this.args.model.contact_email || "");
    if (url1) {
      return I18n.t("about.contact_info", {
        contact_info: `<a href='${url1}' target='_blank'>${url1}</a>`
      });
    } else if (email1) {
      return I18n.t("about.contact_info", {
        contact_info: `<a href="mailto:${email1}">${email1}</a>`
      });
    } else {
      return null;
    }
  }
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <section class="about__header">
        <img class="about__banner" src={{@model.banner_image}} />
        <h3>{{@model.title}}</h3>
        <p class="short-description">{{@model.description}}</p>
        <PluginOutlet
          @name="about-after-description"
          @connectorTagName="section"
          @outletArgs={{hash model=this.model}}
        />
      </section>
      <div class="about__main-content">
        <section class="about__left-side">
          <div class="about__stats">
            {{#each this.stats as |stat|}}
              <span class="about__stats-item {{stat.class}}">
                {{dIcon stat.icon}}
                <span>{{stat.text}}</span>
              </span>
            {{/each}}
          </div>
          <h3>{{i18n "about.simple_title"}}</h3>
          <div>{{htmlSafe @model.extended_site_description}}</div>
        </section>
        <section class="about__right-side">
          <h4>{{i18n "about.contact"}}</h4>
          {{#if this.contactInfo}}
            <p>{{htmlSafe this.contactInfo}}</p>
          {{/if}}
          <p>{{i18n "about.report_inappropriate_content"}}</p>
        </section>
      </div>
    
  */
  {
    "id": "7iXM7vZI",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"about__header\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,0,\"about__banner\"],[15,\"src\",[30,1,[\"banner_image\"]]],[12],[13],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n      \"],[10,2],[14,0,\"short-description\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n      \"],[8,[32,0],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"about-after-description\",\"section\",[28,[32,1],null,[[\"model\"],[[30,0,[\"model\"]]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"about__main-content\"],[12],[1,\"\\n      \"],[10,\"section\"],[14,0,\"about__left-side\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"about__stats\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"stats\"]]],null]],null],null,[[[1,\"            \"],[10,1],[15,0,[29,[\"about__stats-item \",[30,2,[\"class\"]]]]],[12],[1,\"\\n              \"],[1,[28,[32,2],[[30,2,[\"icon\"]]],null]],[1,\"\\n              \"],[10,1],[12],[1,[30,2,[\"text\"]]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n        \"],[10,\"h3\"],[12],[1,[28,[32,3],[\"about.simple_title\"],null]],[13],[1,\"\\n        \"],[10,0],[12],[1,[28,[32,4],[[30,1,[\"extended_site_description\"]]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"section\"],[14,0,\"about__right-side\"],[12],[1,\"\\n        \"],[10,\"h4\"],[12],[1,[28,[32,3],[\"about.contact\"],null]],[13],[1,\"\\n\"],[41,[30,0,[\"contactInfo\"]],[[[1,\"          \"],[10,2],[12],[1,[28,[32,4],[[30,0,[\"contactInfo\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,2],[12],[1,[28,[32,3],[\"about.report_inappropriate_content\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@model\",\"stat\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/about-page.js",
    "scope": () => [PluginOutlet, hash, dIcon, i18n, htmlSafe],
    "isStrictMode": true
  }), this))();
}