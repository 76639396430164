import { action } from "@ember/object";
import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
export default class UserPrivateMessages extends DiscourseRoute {
  static #_ = (() => dt7948.g(this.prototype, "composer", [service]))();
  #composer = (() => (dt7948.i(this, "composer"), void 0))();
  templateName = "user/messages";
  afterModel() {
    this.pmTopicTrackingState.startTracking();
  }
  setupController() {
    super.setupController(...arguments);
  }
  triggerRefresh() {
    this.refresh();
  }
  static #_2 = (() => dt7948.n(this.prototype, "triggerRefresh", [action]))();
  willTransition() {
    super.willTransition(...arguments);
    return true;
  }
  static #_3 = (() => dt7948.n(this.prototype, "willTransition", [action]))();
}