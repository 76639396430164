import Component from "@glimmer/component";
import TopicParticipant from "discourse/components/topic-map/topic-participant";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class TopicParticipants extends Component {
  toggledUsers = (() => new Set(this.args.userFilters))();
  static #_ = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if @title}}
        <h3>{{@title}}</h3>
      {{/if}}
      <div class="topic-map__users-list {{unless @title '--users-summary'}}">
        {{#each @participants as |participant|}}
          <TopicParticipant
            @participant={{participant}}
            @toggledUsers={{this.toggledUsers}}
          />
        {{/each}}
      </div>
    
  */
  {
    "id": "doMtkXus",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,\"h3\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[15,0,[29,[\"topic-map__users-list \",[52,[51,[30,1]],\"--users-summary\"]]]],[12],[1,\"\\n\"],[42,[28,[31,3],[[28,[31,3],[[30,2]],null]],null],null,[[[1,\"        \"],[8,[32,0],null,[[\"@participant\",\"@toggledUsers\"],[[30,3],[30,0,[\"toggledUsers\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@title\",\"@participants\",\"participant\"],false,[\"if\",\"unless\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/topic-participants.js",
    "scope": () => [TopicParticipant],
    "isStrictMode": true
  }), this))();
}