import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <input
  id={{this.inputId}}
  type="search"
  autocomplete="off"
  value={{this.search.activeGlobalSearchTerm}}
  placeholder={{i18n "search.title"}}
  aria-label={{i18n "search.title"}}
  {{on "keyup" this.onKeyup}}
  {{on "keydown" this.onKeydown}}
  {{on "input" this.updateSearchTerm}}
  {{on "focus" @openSearchMenu}}
  {{did-insert this.focus}}
/>
*/
{
  "id": "066zAJsV",
  "block": "[[[11,\"input\"],[16,1,[30,0,[\"inputId\"]]],[24,\"autocomplete\",\"off\"],[16,2,[30,0,[\"search\",\"activeGlobalSearchTerm\"]]],[16,\"placeholder\",[28,[37,0],[\"search.title\"],null]],[16,\"aria-label\",[28,[37,0],[\"search.title\"],null]],[24,4,\"search\"],[4,[38,1],[\"keyup\",[30,0,[\"onKeyup\"]]],null],[4,[38,1],[\"keydown\",[30,0,[\"onKeydown\"]]],null],[4,[38,1],[\"input\",[30,0,[\"updateSearchTerm\"]]],null],[4,[38,1],[\"focus\",[30,1]],null],[4,[38,2],[[30,0,[\"focus\"]]],null],[12],[13]],[\"@openSearchMenu\"],false,[\"i18n\",\"on\",\"did-insert\"]]",
  "moduleName": "discourse/components/search-menu/search-term.hbs",
  "isStrictMode": false
});